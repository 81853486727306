import React from 'react';

import styled from 'styled-components';

const Article = styled.article`
  max-width: 900px;
  overflow-x: hidden;
  min-height: 100vh;
  padding: 1rem;
  margin: 1rem 2rem;

  h2 {
    margin-top: 3rem;
  }

  a {
    color: #097358;
    font-weight: bold;
  }

  blockquote {
    p {
      margin-top: 0;
    }
  }
  blockquote:before {
    color: #ccc;
    content: 'WSKAZÓWKA:';
    display: inline;
    font-size: 1rem;
    padding-bottom: 0;
    /* line-height: 0.1em; */
    /* margin-right: 0.25em; */
    /* vertical-align: -0.4em; */
  }

  .gatsby-code-button-container {
    position: relative;
  }
  .gatsby-code-button {
    cursor: pointer;
    position: absolute;
    right: 0;

    &:hover {
      .gatsby-code-button-icon {
        fill: #097358;
      }
    }
  }
`;

const ArticleContent = ({ html, className, children }) => {
  return (
    <Article className={className || ''}>
      {children}
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Article>
  );
};

export default ArticleContent;

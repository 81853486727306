import React from 'react';
import { graphql } from 'gatsby';
import ArticleContent from '../components/ArticleContent';
import ArticleTableOfContents from '../components/ArticleTableOfContents';
import ArticleLayout from '../components/Layout/ArticleLayout';
import { getUrlWithoutLast } from '../utils';

const IndexPage = ({ data, location }) => {
  const { pageContent } = data;
  return (
    <ArticleLayout>
      <ArticleTableOfContents
        html={pageContent.tableOfContents}
        backlink={getUrlWithoutLast(location.pathname)}
      />
      <ArticleContent html={pageContent.html} />
    </ArticleLayout>
  );
};

export default IndexPage;

export const query = graphql`
  query($id: String!) {
    pageContent: markdownRemark(frontmatter: { id: { eq: $id } }) {
      html
      tableOfContents
    }
  }
`;

/* eslint-disable react/no-danger */
import { Link } from 'gatsby';
import React from 'react';

import styled from 'styled-components';

const Container = styled.article`
  min-width: 280px;
  overflow-x: hidden;
  padding: 1rem;
  font-size: 0.8rem;

  p {
    margin: 0;
  }

  ul {
    padding-inline-start: 1rem;
  }

  .table {
    position: fixed;
    max-width: 280px;
    padding-right: 1rem;
    border-right: 1px solid #ccc;

    @media (max-width: 1000px) {
      display: none;
    }
  }
`;

const ArticleTableOfContents = ({ html, backlink }) => {
  // eslint-disable-next-line react/no-danger

  return (
    <Container>
      <section className="table">
        {backlink && <Link to={backlink}>Powrót</Link>}
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </section>
    </Container>
  );
};

export default ArticleTableOfContents;

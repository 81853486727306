import React from 'react';
import styled from 'styled-components';

const Main = styled.main`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  display: flex;
  background: #fff;
  flex-wrap: wrap;
  a {
    border-bottom: none;
  }
`;

const ArticlePage = ({ children }) => {
  return <Main>{children}</Main>;
};

export default ArticlePage;
